
import { defineComponent, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions } from "@/store/enums/StoreEnums"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import $ from 'jquery'
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import ToolingIcon from './icons/IconTooling.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import CommunityIcon from './icons/IconCommunity.vue'
import SupportIcon from './icons/IconSupport.vue'

export default defineComponent({
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const onMemberNew = () => {
      router.push({ name: "member_new" })
    }

    const goToMember = (id) => {
      router.push({ name: "member_new", params: {id: id} })
    }

    const members = ref([] as any)

    const query = () => {
      store
        .dispatch(Actions.GET_ADMINS)
        .then(() => {
          members.value = store.getters.currentAdmins
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    query()

    // const onDelete = (id) => {
    //   store
    //     .dispatch(Actions.DELETE_ADMIN)
    //     .then(() => {
    //       members.value = store.getters.currentAdmins
    //     })
    //     .catch(() => {
    //       // const [error] = Object.keys(store.getters.getErrors);
    //       // Swal.fire({
    //       //   text: store.getters.getErrors[error],
    //       //   icon: "error",
    //       //   buttonsStyling: false,
    //       //   confirmButtonText: "Try again!",
    //       //   customClass: {
    //       //     confirmButton: "btn fw-bold btn-light-danger",
    //       //   },
    //       // });
    //     })
    // }

    return {
      onMemberNew,
      members,
      goToMember,
      // onDelete
    }
  }
})
